import { useState, useCallback, useContext, useEffect, useRef } from 'react'
import { Div, Context } from 'Shared'
import styled from 'styled-components'
import { engName, histogram, jpnName, key } from 'utils'
import ShinkansenImg from 'images/shinkansen.png'
import CostcoImg from 'images/costco.png'
import DataGrid from 'react-data-grid'

const RecordContainer = styled(Div)`
    border: 1px solid black;
    min-height: 40px;
    max-height: 50px;
`

const Icon = styled.img`
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
`

function Record({data}) {
    const searchLink = `https://www.google.com/maps/search/${engName(data)}+station/@35.6707246,139.6498826,11.25z`
    return(
        <tr>
            <td><a href={searchLink}>{engName(data)}</a> ({jpnName(data)})</td>
            <td>{data.restaurantScore.toFixed(2)}</td>
            <td>{data.priceScore.toFixed(2)}</td>
            <td>{data.barScore.toFixed(2)}</td>
            <td>{data.depravityScore.toFixed(2)}</td>
            <td>
                {data.isNearCostco && <Icon src={CostcoImg} />}
                {data.isNearShinkansen && <Icon src={ShinkansenImg} />}
            </td>
        </tr>
    )
}

const Container = styled(Div)`
    flex-direction: column;
    display: block;
    padding-left: 80px;
    padding-top: 25px;

`

const Table = styled.table`
`

export default function ListView() {
    const [filteredStationData] = useContext(Context.FilteredStationDataContext)

    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>Name </th>
                        <th>Restaurant score</th>
                        <th>Price score</th>
                        <th>Bar score</th>
                        <th>Depravity score</th>
                        <th>Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredStationData.map(d => <Record key={key(d)} data={d} />)}
                </tbody>
            </Table>
        </Container>
    )
}