import GraphFilter from './GraphFilter'
import styled from 'styled-components'
import TriButtonFilter from './TriButtonFilter'
import { Div, Context } from 'Shared'
import { Button } from '@mui/material'
import { useState } from 'react'
import HelpIcon from './HelpIcon'
import Tippy from '@tippyjs/react'

const Title = styled(Div)`
`
export {Title}

const Container = styled(Div)`
    min-width: 22vw;
    max-width: 22vw;
    flex-direction: column;
    background-color: #2F4F4F;
    color: #E4FFFE;

    @media only screen and (max-width: 800px) {
        position: fixed;
        min-width: 100vw;
        min-height: 100vh;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: ${props => props.showFilter ? 'inherit' : 'none'};
    }
`

const StyledFilterButon = styled.button`
    display: none;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 100;
    padding: 9px;

    @media only screen and (max-width: 800px) {
        display: ${props => props.showFilter ? 'none' : 'inherit'};
    }
`

function ShowFilterButton() {
    return (
        <StyledFilterButon>Options</StyledFilterButon>
    )
}

const StyledApplyButton = styled.button`
    display: none;
    background-color: #2F4F4F;
    margin-top: 20px;
    padding: 7px;
    @media only screen and (max-width: 800px) {
        display: inherit;
    }
`

function ApplyButton() {
    return (
        <StyledApplyButton>Apply</StyledApplyButton>
    )
}

const AboutButtonContainer = styled.div`
    padding: 14px 0px 8px 0px;
    cursor: default;
`

function AboutEkiRateButton() {
    const aboutText = 'EkiRate is a site to assist you in finding a place to live! Since Tokyo is somewhat unique in that the station you live nearest is a significant factor in what amenities are available, I did my best to assign a variety of ratings to each station. Once you narrow down the stations that meet your criteria, you can then use Suumo, Homes.co.jp, etc to look for housing in those areas.'
    return (
        <AboutButtonContainer>
            <Tippy content={aboutText}>
                <span>What is EkiRate?</span>
            </Tippy>
        </AboutButtonContainer>
    )
}

export default function FilterBar() {
    // Note: this is only used for small screens
    const [showFilter, setShowFilter] = useState(false)

    const priceHelpText = 'The relative price of real estate at each station. Each bar has the same range on the x-axis.'
    const foodHelpText = 'The availability of ethnic food near the station. Ethnic food is defined as Mexican, Thai, and Indian, because those are my favorites :)'
    const barHelpText = 'The availability of bars near the station.'
    const depravityHelpText = 'The availability of snacks/girls bars near the station. Higher value suggests the area around the station is more adult-focused.'
    return (<>
        <Container showFilter={showFilter}>
            <GraphFilter title="Real estate" sliderLabels={['Cheap', 'Expensive']} selector='priceScore' helpText={priceHelpText} />
            <GraphFilter title="Ethnic food" sliderLabels={['Scarce', 'Abundant']} selector='restaurantScore' helpText={foodHelpText} />
            <GraphFilter title="Bars" sliderLabels={['Dry', 'Irish']} selector='barScore' helpText={barHelpText} />
            <GraphFilter title="Depravity" sliderLabels={['Pure', 'Vile']} selector='depravityScore' helpText={depravityHelpText} />
            <TriButtonFilter title="Near shinkansen?" selector="isNearShinkansen" helpText='If a shinkansen stop is within 3km'/>
            <TriButtonFilter title="Near costco?" selector="isNearCostco" helpText='If a costco is within 12km'/>
            <StyledApplyButton onClick={() => setShowFilter(false)}>Apply</StyledApplyButton>
            <AboutEkiRateButton />
        </Container>
        <StyledFilterButon showFilter={showFilter} onClick={() => setShowFilter(true)}>Options</StyledFilterButon>
        </>
    )
}